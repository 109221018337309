<template>
  <div class="contentblocksPageContainer">
    <div class="header">
      <div class="header-background">
        <div v-if="!isMobile">
          <video
            src="https://player.vimeo.com/progressive_redirect/playback/928720228/rendition/720p/file.mp4?loc=external&signature=6e39582a7315008bd75b1896a472993a62d3fbddc6742e3b701c974086853d34"
            muted="true"
            loop
            autoplay
          />
        </div>
        <!-- poster="~/assets/ecco-video-poster.png" -->

        <!-- TOOD: FIX POSTER, wordt niet opgehaald.... -->

        <img
          class="header-background"
          src="~/assets/home-header.webp"
          alt="Ecoo italia logo"
          v-else
        />
      </div>
      <div class="header-content">
        <!-- <img
          src="~/assets/ecco-logo-tagline.svg"
          alt="Ecoo italia logo grote versie"
          class="eccoLogo"
        /> -->
        <!-- TODO fix this style -->
        <div class="text-center sloganWrapper">
          <h1 class="slogan">
            Ciao! Wij vinden uw <br />
            <strong>perfecte </strong> agriturismo
          </h1>
          <p class="q-mt-md">Agriturismo Italië, knus en authentiek</p>
        </div>
        <div class="column items-center">
          <div class="container">
            <AccomodationFilters :show-reset="false">
              <q-btn
                icon="icon-search"
                class="actionButton searchButton"
                color="primary"
                :to="aanbodRoute"
              >
                Zoek aanbod
              </q-btn>

              <div class="row col-md-12 col-sm-auto col-xs-12 justify-center bottomRow">
                <q-btn
                  icon="icon-filter"
                  class="actionButton"
                  color="primary-dark"
                  outline
                  @click="showVerfijnFilters = true"
                >
                  Filters
                  <q-badge
                    class="amountFilterBadge"
                    floating
                    rounded
                    v-if="amountVerfijnFilters"
                    v-text="amountVerfijnFilters"
                  />
                </q-btn>

                <q-btn
                  class="actionButton"
                  color="primary-dark"
                  @click="store.resetAllFilters"
                  flat
                >
                  <q-icon
                    v-if="!isMobile"
                    name="icon-close"
                    size="sm"
                    class="q-mr-sm"
                  />
                  Reset filters
                </q-btn>
              </div>
            </AccomodationFilters>
          </div>
          <q-btn
            class="adviceButton q-mt-md"
            color="accent"
            :to="routeNames.inquiry"
            rounded
          >
            <q-icon name="icon-search-help" />
            <span class="q-ml-sm">Wij zoeken uw droomvakantie</span>
          </q-btn>
        </div>
      </div>
    </div>
    <div class="">
      <ContentBlocksContainer :data="data" />
    </div>

    <AanbodVerfijnFilters
      v-model="showVerfijnFilters"
      v-if="showVerfijnFilters"
      @set-filters="applyFilters"
      :active-filters="activeFilters"
    />
  </div>
</template>

<script setup lang="ts">
import { HOLIDAY_TYPES, ACCOMODATION_TYPES, FEATURES, QUALITY_LABELS, LABELS, DISCOUNTS, ACCOMODATION_SUB_FILTERS } from '~/config';
import useAuthStore from '~/store/auth';
import useStore from '~/store/store';

const store = useStore();
const route = useRoute();
const data = ref<any>({});
const isMobile = getIsMobile();
const showVerfijnFilters = ref(false);
const paramString = ref('');
const aanbodRoute = computed(() => routeNames.aanbod + '?' + paramString.value);
const activeFilters = ref({});
const amountVerfijnFilters = computed(() => countVerfijnFilters(activeFilters.value));
const authStore = useAuthStore();

function applyFilters(newFilters: VerfijnFilterRecord) {
  activeFilters.value = newFilters;

  const filterData: VerfijnFilterValues = {
    page: 1,
  };
  // TODO: composable hiervoor
  filterData[HOLIDAY_TYPES] = newFilters[HOLIDAY_TYPES]?.map((item: Filter) => item.value);
  filterData[QUALITY_LABELS] = newFilters[QUALITY_LABELS]?.map((item: Filter) => item.value);
  filterData[LABELS] = newFilters[LABELS]?.map((item: Filter) => item.value);
  filterData[DISCOUNTS] = newFilters[DISCOUNTS]?.map((item: Filter) => item.value);
  filterData[ACCOMODATION_TYPES] = newFilters[ACCOMODATION_TYPES]?.map((item: Filter) => item.value);
  filterData[ACCOMODATION_SUB_FILTERS] = newFilters[ACCOMODATION_SUB_FILTERS]?.map((item: Filter) => item.value);
  filterData[FEATURES] = newFilters[FEATURES].filter((item: Filter) => !!item)?.map((item: Filter) => item.value);

  paramString.value = configureSearchParams(filterData);
  showVerfijnFilters.value = false;
}

data.value = await fetchData(`/api/page/4`);
const metadata = data.value.metadata;
setMetaData(metadata);

if (route.query.login !== undefined) {
  authStore.setShowLoginModal(true);
} else if (route.query?.verify) {
  authStore.setShowLoginModal(true);
  authStore.setLoginFlowState(2);
} else if (route.query?.verifyandsetpassword) {
  authStore.setShowLoginModal(true);
  authStore.setLoginFlowState(6);
} else if (route.query?.resetpassword) {
  authStore.setShowLoginModal(true);
  authStore.setLoginFlowState(5);
}
</script>

<style lang="scss" scoped>
video {
  width: 100%;
  // height: 33vw;
  max-height: 700px;
  object-fit: cover;
}
.container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 32px 48px;
  border: 2px solid #d6f0fb;
  align-items: flex-end;
  border-radius: 16px;
  box-shadow: 0px 1px 1px 0px rgba(55, 55, 55, 0.2), 0px 4px 9px 1px rgba(0, 0, 0, 0.2);
}

.actionButton {
  margin-left: 24px;
  margin-top: 12px;
}

:deep(.AccomodationFilters) {
  align-items: flex-end;
  display: flex;
  width: 100%;

  .label {
    text-align: left;
    color: #0e1216;
  }

  .TextInput {
    flex-grow: 2;
  }
}

.index {
  padding-top: 120px;
}

//header styling
.header {
  color: #fff;
  display: flex;
  position: relative;
  height: 600px;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 100px;
  justify-content: center;

  .eccoLogo {
    width: 290px;
    height: 120px;
  }

  .slogan {
    text-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(55, 55, 55, 0.2);
    font-family: 'Nunito Sans';
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 70.4px */
  }

  .header-content {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 12px;
    height: 100%;
    justify-content: space-between;
    gap: 110px;
    padding-top: 65px;
  }
}

.header-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  height: 700px;
  width: 100%;
}

@media (max-width: $screen-md) {
  video {
    width: initial;
  }
}

@media (max-width: $screen-xs) {
  .container {
    padding: 24px;
  }

  .adviceButton {
    margin: 12px;
    display: flex;
    position: relative;
    bottom: initial;
  }

  .searchButton {
    width: 100%;
    // margin-left: 16px;
  }

  .bottomRow {
    justify-content: space-between;
    .actionButton {
      margin: 0;
      width: 46%;
    }
  }

  .actionButton {
    margin-left: 16px;
    margin-top: 16px;
  }

  .filterButton {
    margin-left: 16px;
  }

  .header {
    display: block;
    margin-top: 0;
    height: 100%;
    margin-bottom: 32px;
    h1 {
      font-size: 32px;
    }
    h1,
    p {
      text-align: center;
    }

    .header-content {
      padding-top: 32px;
      gap: 0;

      .sloganWrapper {
        padding-bottom: 12px;
      }
    }
  }

  .header .eccoLogo {
    width: 300px;
    height: 80px;
  }
}
</style>
